/**
 * Created by WebStorm.
 *
 * @project: lin-cms-specialty-market
 * @author: zhangzhang - Lavender
 * @date: 2023/9/21 17:26
 * @description: 商品订单路由
 */

const staticsRouter = {
  route: null,
  name: null,
  title: 'C端数据',
  type: 'folder',
  icon: 'iconfont el-icon-tickets',
  filePath: 'view/statics/',
  sort: null,
  inNav: true,
  children: [
    {
      title: 'C端用户列表',
      type: 'view',
      name: 'CUser',
      route: '/statics/cUserList',
      icon: 'iconfont icon-huiyuanguanli',
      filePath: 'view/statics/c-user-list.vue',
      inNav: true,
      permission: ['C端用户分页查询'],
    },
    {
      title: '订单列表',
      type: 'view',
      name: 'Order',
      route: '/statics/mainOrderList',
      icon: 'iconfont el-icon-document',
      filePath: 'view/statics/main-order-list.vue',
      inNav: true,
      permission: ['主订单分页查询'],
    },
    {
      title: '子订单列表',
      type: 'view',
      name: 'SonOrder',
      route: '/statics/orderList/:mainOrderId',
      filePath: 'view/statics/order-list.vue',
      icon: '',
      permission: ['子订单分页查询'],
    },
  ],
}

export default staticsRouter
